@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");
@import "~bootstrap";

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
.clear-button {
  display: none; /* Скрива хикса от полето за динамично търсене на tom_select */
}
/* За малки екрани (мобилни устройства) */
@media (max-width: 576px) {
  .custom-btn {
    font-size: 12px !important; /* Още по-малък шрифт за малки екрани */
    padding: 6px 10px !important; /* Още по-малки вътрешни разстояния */
  }
  .search-form-container .form-field {
    font-size: 12px !important; /* Още по-малък шрифт за малки екрани */
    padding: 2px 4px !important; /* Още по-малки вътрешни разстояния */
  }
  .form-field label {
    font-size: 12px !important; /* Размер на шрифта на етикета */
  }
  /* Стилизация на текстовите полета */
  .search-form-container .form-control {
    font-size: 11px !important; /* Още по-малък шрифт за малки екрани */
    padding: 4px 8px !important; /* Още по-малки вътрешни разстояния */
    height: 25px !important;
    width: 70px !important;
  }
  h2 {
    font-size: 0.8em !important;
    margin: 0 auto;
    /*white-space: normal !important;*/
    max-width: 40% !important;
  }
  .period-header {
    font-size: 0.8em !important;
    white-space: nowrap !important;
    text-align: center !important;
    max-width: 100% !important;
  }
  .ts-control {
    /*width: 10px !important;
    padding: 0rem !important;*/
  }
  .search-form-container .form-row {
    gap: 0px !important;
  }
  .search-form-container .btn-primary {
    width: 80% !important;
    height: 20% !important;
    font-size: 11px !important;
    white-space: normal;
  }
  .table {
    font-size: 10px !important;
  }
  .btn-info {
    color: #fff;
    background-color: #17a2b8;
    font-size: 10px !important;
  }
  .form-grid {
    grid-template-columns: repeat(
      2,
      1fr
    ) !important; /* Две колони на по-малки екрани */
  }
  .customer {
    grid-column: 1/2 !important;
    grid-row: 1 !important;
  }
  .type {
    grid-column: 2/3 !important;
    grid-row: 1 !important;
  }
  .quadrature {
    grid-column: 1/2 !important;
    grid-row: 2 !important;
  }
  .glass {
    grid-column: 2/3 !important;
    grid-row: 2 !important;
  }
  .detail {
    grid-column: 2/3 !important;
    grid-row: 3 !important;
  }
  .mosquito {
    grid-column: 2/3 !important;
    grid-row: 4 !important;
  }
  .price {
    grid-column: 1/2 !important;
    grid-row: 3 !important;
  }
  .for_date {
    grid-column: 1/2 !important;
    grid-row: 4 !important;
  }
  .note {
    grid-column: 1/2 !important;
    grid-row: 5 !important;
  }
  .create-submit {
    grid-column: 2/3 !important;
    grid-row: 5 !important;
  }

  .form-field-create label {
    font-size: 12px !important;
  }
  .form-control {
    width: 6rem !important;
    height: 1.5rem !important;
    font-size: 12px !important;
    padding: 0.1rem !important;
  }
  .ts-wrapper.form-control {
    width: 100% !important; /* Задействай ширината само за малките екрани */
    height: 1.5rem !important;
  }
  .ts-wrapper {
    min-height: 1.5rem !important;
    padding-bottom: 0.1rem !important;
  }

  .ts-wrapper.single .ts-control input {
    font-size: 12px !important; /* По-малък шрифт за малки екрани */
  }
  .form-field-create {
    display: flex;
    flex-direction: column; /* Вертикално подреждане */
    justify-content: center; /* Центриране на съдържанието */
  }
  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    background-color: hsl(210, 14%, 97%);
    align-content: center;
    text-align: center !important;
    align-items: center;
    font-size: 10px !important;
  }
  .btn-secondary {
    font-size: 12px !important;
    padding: 3px 6px !important; /* вътрешни отстъпи */
  }
  .btn-success {
    font-size: 12px !important;
    padding: 3px 6px !important;
  }
  .table-container {
    text-align: center !important;
    font-size: 0.5em !important;
  }
  #form-reference {
    width: 100% !important;
  }
}

/* За средни екрани (таблети) */
@media (min-width: 577px) and (max-width: 768px) {
  .custom-btn {
    font-size: 12px !important; /* Намаляване на шрифта */
    padding: 6px 10px !important; /* Намаляване на вътрешните разстояния */
  }
}

/* За големи екрани (десктоп) */
@media (min-width: 769px) {
  .custom-btn {
    font-size: 15px !important; /* Още по-малък шрифт за малки екрани */
    padding: 8px 12px !important; /* Още по-малки вътрешни разстояния */
  }
}
.container-fluid .row {
  margin-right: 0;
  margin-left: 0;
  padding-right: 15px;
  padding-left: 15px;
}

.row {
  margin: 0; /* Премахва външните отстояния */
  padding: 0; /* Премахва вътрешните отстояния, ако е необходимо */
}

.header {
  background-image: url("../images/IMG_6529.jpg");
  background-size: cover;
  background-position: center;
  height: 20vh; /* Височина в проценти от височината на прозореца (viewport height) */
  display: flex;
  align-items: center;
  justify-content: space-between; /* За да държим логото и текста на потребителя в рамките на заглавката */
  padding: 10px; /* Малко padding за вътрешен отстъп */
  align-items: flex-end; /* Поставя съдържанието в началото по вертикала */
}

/* Стилове за реда преди логин (логин форма) */
.headerLogin {
  background-color: rgba(122, 201, 208, 0.6);
}
.col-sm-10 img {
  max-width: 50%; /* Логото ще заема 50% от ширината на колоната */
  height: auto; /* Височината на логото ще се адаптира пропорционално */
}

/* Позициониране на текста */
.user-col {
  display: flex; /* Активира Flexbox */
  flex-direction: column; /* Подрежда елементите по вертикала */
  justify-content: flex-end; /* Поставя елементите най-долу */
  text-align: right;
}
.user-text {
  color: hsl(204, 33%, 97%); /* Цвят на текста */
  font-size: 1em; /* Размер на шрифта */
  font-weight: normal; /* Обикновен шрифт */
  /*margin-top: 10px; /* Горен отстъп */
}

form {
  background-color: transparent; /* Премахва сивото задноразстояние */
  padding: 0; /* Премахва вътрешното разстояние на формата */
  margin: 0 auto; /* Центрира формата на страницата */
  max-width: 600px; /* Ограничава максималната ширина на формата */
  width: auto; /* Автоматична ширина в зависимост от съдържанието */
  border-radius: 20px; /* Закръгляване на ъглите */
}

.custom-btn {
  display: flex;
  padding: 10px 15px;
  border: none; /* Премахване на бордера */
  margin: 5px; /* Разстояние между бутоните */
  text-decoration: none; /* Без подчертаване на текста */
  background-color: #007bff; /* Основен цвят на бутона */
  color: white; /* Цвят на текста */
  border-radius: 5px; /* Заоблени ъгли */
  align-items: center; /* Центрира по вертикала */
  justify-content: center; /* Центрира по хоризонтала */
  white-space: nowrap; /* Предотвратява пренасянето на текста на нов ред */
  max-width: 150px; /* Максимална ширина на бутоните */
  width: auto; /* Позволява бутоните да заемат динамично място */
  flex: 1 1 auto; /* Позволява бутоните да растат и се свиват */
  font-size: 15px;
}

.custom-btn:hover {
  background-color: #0056b3; /* Цвят на бутона при задържане */
  color: white; /*Текстът остава видим при натискане*/
  text-decoration: none;
}

.btn-primary {
  width: 100%; /* Заема цялата ширина на родителя си */
  border: none; /* Премахва границата на бутона */
  border-radius: 0 !important; /* Закръгляване на ъглите на бутона */
  /*background: linear-gradient(90deg, rgb(177, 176, 206) 0%, rgba(75,14,154,1) 35%, rgba(0,212,255,1) 100%); /* Градиентен фон на бутона */
  margin-right: 10px; /* Отстояние между бутоните */
}

.form-control {
  color: rgba(0, 0, 0, 0.87); /* Цвят на текста */
  border-bottom-color: rgba(
    0,
    0,
    0,
    0.42
  ); /* Цвят на долната граница на текстовото поле */
  box-shadow: none !important; /* Премахва сянката */
  border: none; /* Премахва границата */
  border-bottom: 1px solid; /* Добавя долна граница */
  border-radius: 4px 4px 0 0; /* Закръгляване на горните ъгли */
}

/* Центриране на бутоните и фонов цвят */
.btn-group {
  display: flex;
  justify-content: center; /* Центриране по хоризонтала */
  align-items: center; /* Центриране по вертикала */
  background-color: #eee4e4c4; /*search-form-container */
  flex-wrap: wrap;
}
.btn-group a.custom-btn,
.btn-group button.custom-btn {
  flex: 1; /* Задава бутоните да имат еднаква ширина */
  margin: 5px; /* Добавя малко разстояние между бутоните */
}

.search-form-container {
  background-color: #eee4e4c4; /* Цвят на фона */
  width: 100%;
  margin-top: 20px; /* Отстъп отгоре */
  margin-bottom: 20px;
}

.search-form-container .form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Разстояние между полетата */
}

.search-form-container .form-field {
  flex: 1 1 30%; /* Разпределение на ширината на полетата */
}
.form-field label {
  font-size: 18px; /* Размер на шрифта на етикета */
}
#search_form_use_completion_date {
  margin-top: 10px; /* Примерно отстояние отгоре */
  margin-left: 5px; /* Примерно отстояние отляво */
}
#search_form_use_completion_date + label {
  margin-left: 30px; /* Разстояние между чекбокса и текста */
}

.search-form-container .btn-primary {
  width: 100%; /* Заема цялата ширина на контейнера */
  border-radius: 20px; /* Закръгляване на ъглите на бутона */
  background: linear-gradient(
    90deg,
    rgb(177, 176, 206) 0%,
    rgba(75, 14, 154, 1) 35%,
    rgba(0, 212, 255, 1) 100%
  ); /* Градиентен фон на бутона */
  margin-top: 10px; /* Отстъп отгоре */
  border: none; /* Премахва границата на бутона */
}
/* Увеличаване на размера на чекбокса */
.form-check-input {
  transform: scale(1.5); /* Скалиране на чекбокса */
  width: 15px; /* За контролиране на hit area */
  height: 15px; /* За контролиране на hit area */
}

/* Контейнерът на бутона */
.form-submit-container {
  display: flex;
  justify-content: flex-end; /* Премества бутона вдясно */
  margin-top: 20px; /* Добавя малко разстояние от полетата над него */
}

/* Стилизация на бутона */
.submit-button {
  width: 50px; /* Ширината на бутона */
  height: 50px; /* Прави бутона квадратен */
  display: inline-flex;
  justify-content: center;
  align-items: center; /* Центриране на текста в бутона */
  border-radius: 5px; /* Може да коригираш за да е леко заоблен бутон */
  font-size: 16px; /* Размер на текста вътре */
  text-transform: uppercase; /* Прави текста главни букви */
}

/* Стилизация на текстовите полета */
.search-form-container .form-control {
}
/* форма за нова поръчка и редакция на поръчка*/
.create-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5; /* Можеш да добавиш цвят за заглавието */
}
.create-header h2 {
  flex-grow: 1; /* Заставя заглавието да заеме наличното пространство */
  text-align: center; /* Центрира текста вътре в заглавието */
}

.create-header a {
  margin-right: auto;
}

.create-form-container {
  background-color: #eee4e4c4;
  /*max-width: 800px;*/
  margin-top: 20px; /* Естествено отстояние от заглавието */
  margin-bottom: 20px;
  padding: 20px;
}
.form-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Три колони с равни ширини */
  grid-gap: 20px; /* Разстояние между полетата */
  align-items: end; /* Подравняване на всички полета по долния край */
}
.customer {
  grid-column: 1/2;
}
.type {
  grid-column: 2/3;
}
.quadrature {
  grid-column: 3/4;
}
.glass {
  grid-column: 1/2;
}
.detail {
  grid-column: 2/3;
}
.mosquito {
  grid-column: 3/4;
}
.price {
  grid-column: 1/2;
}
.for_date {
  grid-column: 2/3;
}
.note {
  grid-column: 3/4;
}
.create-submit {
  grid-column: 2/3;
}

.form-field-create,
.form-field.form-check {
  display: flex;
  flex-direction: column;
}

.form-field-create.full-width {
  grid-column: span 3; /* Бутонът заема цялата ширина */
}
.form-check-label {
  display: inline-block; /* Уверяваме се, че label се държи като блок елемент */
  /*white-space: nowrap;   /* Принуждаваме label-а да стои на един ред */
  /*vertical-align: middle; /* Подравняваме label спрямо чекбокса по вертикала */
  height: 20px; /* Или друга подходяща стойност за еднаква височина */
}

.create-submit {
  margin-top: 5px;
}

.form-check {
  display: flex;
  /*align-items: center; /* Центрира чекбоксовете по вертикала */
  width: 100%; /* Уверяваме се, че заемаме цялата ширина, като другите полета */
  margin-left: 5px; /* Изравняваме чекбоксовете спрямо лявата страна */
}

/* Увеличаване на размера на чекбокса */
.form-check-input {
  transform: scale(1.5); /* Скалиране на чекбокса */
  width: 15px; /* За контролиране на hit area */
  height: 15px; /* За контролиране на hit area */
}
.recent-orders-title {
  text-align: center; /* Центрира текста по хоризонтала */
  margin-top: 15px; /* Отстъп отгоре, за да има място под формата */
  margin-bottom: 0px; /* Намалява разстоянието между заглавието и таблицата */
  /*font-weight: bold; /* Добавяме малко тежест на шрифта, ако е необходимо */
  position: relative;
  top: 10px; /* Контролира позицията надолу */
}
.recent-orders-table {
  margin-top: -15px; /* Отрицателният маргин се прилага само за тази таблица */
}
.checkboxes-grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Три колони с равни ширини */
  grid-gap: 20px; /* Разстояние между полетата */
  margin-left: 10px;
  margin-top: 10px;
  /*align-items: end; /* Подравняване на всички полета по долния край */
}
.form-field-create label {
  font-size: 18px;
}
.checkbox-item label {
  /*display: inline-block; /* Уверяваме се, че label се държи като блок елемент */
  white-space: nowrap; /* Принуждаваме label-а да стои на един ред */
  /*vertical-align: middle; /* Подравняваме label спрямо чекбокса по вертикала */
  /* height: 40px; /* Или друга подходяща стойност за еднаква височина */
  margin-left: -10px;
  text-align: center;
  font-size: 18px;
}
.input-check-edit {
  transform: scale(1.5); /* Скалиране на чекбокса */
  width: 15px; /* За контролиране на hit area */
  height: 15px; /* За контролиране на hit area */
  margin-left: 5px;
  text-align: center;
}
.form-grid .checkbox-item label {
  margin-left: 10px;
}
/*До тук за формата за нова поръчка и edit*/

.customer-autocomplete-field {
  width: 100% !important; /* или по ваш избор */
}

.modal-body button {
  display: none;
}
.my-form-class {
  background-color: #eddbdb; /* Сив цвят на формата */
  padding: 20px; /* Подравняване на съдържанието вътре във формата */
}

.my-label-class {
  background-color: red; /* Променете цвета на етикетите на червено */
  font-weight: bold; /* Оставете текста удебелен */
}

.my-input-class {
  order-color: green; /* Променете цвета на границата на входното поле на зелено */
  background-color: yellow; /* Променете цвета на фона на входното поле на жълто */
  width: 100% !important; /* Заето на цялото ширината на родителския елемент */
  box-sizing: border-box !important; /* Размер на полето включва и границата */
  padding: 10px !important; /* Вътрешно разстояние */
  border: 1px solid #ccc !important; /* Граница */
  border-radius: 5px !important; /* Закръгление на краищата */
  background-color: white !important; /* Бял цвят на входните полета */
  margin-bottom: 10px !important; /* Разстояние между входните полета */
}

.tref {
  border: 1px solid black;
  border-collapse: collapse;
}

.tref th,
.tref td {
  border: 1px solid black;
  border-collapse: collapse; /* Слива границите на клетките */
  background-color: #f2f2f2;
  color: #333;
  font-weight: bold;
  padding: 8px;
  text-align: left;
}

.tref th {
  border-bottom: 2px solid #ddd; /* Горна граница на клетката */
}

.search-form {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.form-group {
  margin-right: 10px; /* Разстояние между полетата */
}

.form-row {
  display: flex;
  flex-wrap: wrap;
}

.form-field {
  flex-grow: 1;
  margin-right: 10px;
}

.form-field:last-child {
  margin-right: 0;
}
/* Опционално: Стилизиране на бутона за търсене */
button[type="submit"] {
  padding: 5px 10px;
  /*background-color: #00ff2a;*/
  color: white;
  border: none;
  cursor: pointer;
}
.table-collection {
  background-color: #baf0ad; /* избери подходящ цвят */
  padding: 10px; /* пространство около таблиците */
  border-radius: 5px; /* закръглени ъгли, ако желаеш */
  margin-top: 20px;
}

.table-container {
  max-height: 300px; /* Определяне на височината на скролируемата част */
  overflow-y: auto;
  border: 3px solid #0f0e0e; /* Добавяне на граница за контейнера */
  text-align: center;
  background-color: #f4eef0;
}
.table-container th {
  background-color: #dddddd;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  background-color: hsl(210, 14%, 97%);
  align-content: center;
  text-align: center !important;
  align-items: center;
}

.table-sticky th {
  background: #fff;
  border-bottom: 2px solid #ddd;
  position: sticky;
  top: 0;
  z-index: 990;
}
.table .thead-light th {
  color: hsl(204, 38%, 97%);
  background-color: #434b53;
  border-color: #1b1b1b;
}
.table-sm td {
  padding: 0.5em !important;
}

/* за страницата подробна справка за поръчка */
.example-wrapper {
  text-align: center;
  margin-bottom: 20px;
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 100vh; /* Поне толкова, колкото е височината на прозореца */
}

.example-wrapper h1 {
  margin-top: 0;
}

.example-wrapper table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.example-wrapper table th {
  background-color: #ddd;
  padding: 10px;
}

.example-wrapper table th,
.example-wrapper table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}

.tables-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.tables-group .table-container {
  width: 48%; /* Ширина на контейнерите за таблиците */
}

.tables-group .table-container table {
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
}

.tables-group .table-container table th {
  background-color: #ddd;
  padding: 10px;
}

.tables-group .table-container table th,
.tables-group .table-container table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}
/* до тук е за ПОДРОБНА СПРАВКА ЗА ПОРЪЧКА */

/* за пагинацията */
.pagination {
  display: flex;
  justify-content: center;
}
.page-item.active .page-link {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}

.page-item .page-link {
  color: blue;
  border: 1px solid blue;
}

.page-item.disabled .page-link {
  color: #ccc;
}

.page-item:not(:last-child) .page-link {
  margin-right: 0.25em;
}

.page-link:hover {
  background-color: #ccf;
}
/* до тук е за пагинацията */
/* скриване на полета за съкратена версия*/
.hidden {
  display: none;
}
/*скриване на полетата във формата за търсене*/
.hidden-field {
  display: none;
}
/*за таблиците в справката за топ поръчки*/
.tables-container {
  display: flex; /* Подрежда децата на контейнера в ред (хоризонтално) */
  justify-content: space-between; /* Разстояние между таблиците */
  gap: 20px; /* Разстояние между всяка от таблиците */
}

.table-wrapper {
  flex: 1; /* Означава, че всяка таблица ще заема равно място */
  padding: 10px;
  border: 1px solid #ccc; /* Малка граница около всяка таблица */
  background-color: #f9f9f9; /* Фонов цвят за яснота */
}

/*Оцветява клетката за дължима сума*/
.green {
  background-color: #59e379 !important ; /* светло зелено */
}

.red {
  background-color: #e7626d !important; /* светло червено */
}

.first {
  background-color: #efefe4 !important ; /* бяло */
  align-content: center !important;
  text-align: center !important;
  vertical-align: middle !important; /* Вертикално центриране */
}
.second {
  background-color: #def439 !important ; /* жълто */
}
.third {
  background-color: #ce4416 !important ; /* кафяво */
}
.last {
  background-color: #67da82 !important ; /* светло зелено */
}
.custom-alert {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 5px;
  font-size: 18px;
  display: none;
  z-index: 9999;
}

#successPayment {
  background-color: green;
}

#errorMessage {
  background-color: red;
}
.ts-control {
  width: 10rem;
  flex-wrap: nowrap;
  white-space: nowrap; /* Предотвратява пренасянето */
}
.ts-wrapper.form-select,
.ts-wrapper.single {
  --ts-pr-caret: 2.25rem;
}
.plugin-clear_button {
  --ts-pr-clear-button: 1em;
}
.item {
  /*width: 20rem !important;*/
  flex-wrap: nowrap !important;
  white-space: nowrap !important; /*Предотвратява пренасянето */
}
